import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

const ConsignmentPage = () => (
    <Layout>
        <SEO title="Consignment" />
        <header className="small">
            <h2>Consignment</h2>
        </header>
        <div className="inner">
            <p>Consignment options are available for local businesses.</p>
            <p>
                Please <Link to="/contact">contact us</Link> for more
                information.
            </p>
        </div>
    </Layout>
);

export default ConsignmentPage;
